const endpoint = '/api/staff'

class api {
    static login() { return '/api/login' }

    //User
    static currentUser() { return endpoint+'/user/current' }
    static userAuthenticated() { return endpoint+'/user/authenticated'}
    static users() { return endpoint+'/user'}
    static updateLanguageUser() { return endpoint+'/user/language'}
    static saveUser() { return endpoint+'/user'}
    static userPermissions() { return endpoint+'/user/permission'}
    static userNotifications() { return endpoint+'/user/notification'}
    static userReadNotification() { return endpoint+'/user/notification/read/'}
    static userDeleteNotification() { return endpoint+'/user/notification/delete/'}
    static userUpdateProfile() { return endpoint+'/user/profile' }

    //BICIMAX.COM
    static bicimaxCategories() { return endpoint+'/bicimax-com/categories'}
    static bicimaxBrands() { return endpoint+'/bicimax-com/brands'}
    static bicimaxSyncProducts() { return endpoint+'/bicimax-com/sync'}
    static bicimaxBrandBanner(brand){ return endpoint + '/bicimax-com/configurations/'+brand }
    static bicimaxCreateBrandBanner() { return endpoint + '/bicimax-com/configurations' }
    static bicimaxDeleteBrandBanner(bannerId) { return endpoint + '/bicimax-com/configurations/'+bannerId }

    //FORMS
    static formsList(){ return endpoint+'/forms'}
    static form(formID){ return endpoint+'/forms/'+formID}
    static formStats(formID) {return endpoint +'/forms/'+formID+'/stats'}
    static formUserStats(formID, userEmail) {return endpoint +'/forms/'+formID+'/stats/'+userEmail}
    static createForm(){ return endpoint+'/forms'}
    static formSaveInfo(formID){ return endpoint+'/forms/'+formID}
    static formSaveStepInfo(formID, stepID) {return endpoint+'/forms/'+formID+'/steps/'+stepID}
    static formSaveQuestion(formID, questionID){return endpoint+'/forms/'+formID+'/questions/'+questionID}
    static formSaveQuestionTableGroup(formID,tableGroupID) {return endpoint+'/forms/'+formID+'/tableGroup/'+tableGroupID}
    static formSaveQuestionActivatorOption(formID, questionID) {return endpoint+'/forms/'+formID+'/questions/'+questionID+'/requiredOption'}
    static formDeleteForm(formID){return endpoint+'/forms/'+formID}
    static formDeletePlatform(formID, platformID) { return endpoint+'/forms/'+formID+'/platforms/'+platformID}
    static formDeleteStep(formID, stepID) {return endpoint+'/forms/'+formID+'/steps/'+stepID}
    static formDeleteQuestion(formID, questionID){return endpoint+'/forms/'+formID+'/questions/'+questionID}
    static formDeleteQuestionOption(formID, questionID, optionID){return endpoint+'/forms/'+formID+'/questions/'+questionID+'/options/'+optionID}
    static formsSteps(formID) {return endpoint+'/forms/'+formID+'/steps'}

    //Permission
    static permissions() { return endpoint+'/permission'}

    //Profiles
    static profiles() { return endpoint+'/profile'}
    static profilesPermissions() { return endpoint+'/profile/permission' }

    //Products
    static products() { return endpoint+'/products' }
    static productsBikes() { return endpoint+'/bikes' }
    static productsFamilies() { return endpoint+'/products/families' }
    static productsSubFamilies() { return endpoint+'/products/subFamilies' }
    static productsTypes() { return endpoint+'/products/types' }
    static productsChangeVisibility() { return endpoint+'/products/changeVisibility' }
    static productsCancel() { return endpoint+'/products/cancel' }
    static productsIntrasat() { return endpoint+'/products/intrasat' }
    static productsUpdateStockMinRep() { return endpoint+'/products/updateStockMinRep' }
    static productsBrandsDiscounts() {return endpoint+'/products/brandsDiscounts' }
    static productsBrandsDiscountsLevelsClients() {return endpoint+'/products/brandsDiscounts/levels/clients' }
    static productsEcovalor() { return endpoint+'/products/ecovalor' }
    static productsUpdateField() { return endpoint+'/products/field' }
    static productsWarehouses() { return endpoint+'/products/warehouses' }
    static productsTransfer() { return endpoint+'/products/transfer' }
    static productsUpdatePVPs() { return endpoint+'/products/updatePVPs' }

    //Brands
    static brandsStock() { return endpoint+'/brands/stock' }
    static brandsStocksBikes() { return endpoint+'/brands/stocksBikes' }
    static brandsStocksBikesDownload() { return endpoint+'/brands/stocksBikes/download' }
    static brandsImportOrdersECF() { return endpoint+'/brands/importerECF/xls' }
    static brandsImportECFSuppliers() { return endpoint+'/brands/importECF/suppliers' }
    static brandsImportECFAnalyze() { return endpoint+'/brands/importECF/analyzeXls'}
    static brandsSubmitECF() { return endpoint+'/brands/importECF/submit' }

    //Families
    static families() { return endpoint+'/families' }
    static subFamilies() { return endpoint+'/subFamilies' }

    //B2B
    static b2bCampaignsUpdateInfo() { return endpoint+'/campaigns/' }
    static b2bCampaignsUpdateLevels() { return endpoint+'/campaigns/' }
    static b2bCampaignsUpdateConditions() { return endpoint+'/campaigns/' }

    static b2bLogins() { return endpoint+'/b2b/logins' }
    // static b2bBanners() { return endpoint+'/b2b/banners' }
    static b2bBannersMini() { return endpoint+'/b2b/banners/mini' }
    static b2bBannersBrands() { return endpoint+'/b2b/banners/brands' }
    static b2bSCPrice() { return endpoint+'/b2b/priceSC' }
    static b2bCoupons() { return endpoint+'/b2b/coupon' }
    static b2bCampaigns() { return endpoint+'/campaigns' }
    static b2bCampaignsAdd() { return endpoint+'/b2b/campaigns/new' }
    static b2bCampaignsSaveBasic() { return endpoint+'/b2b/campaigns/save/basic' }
    static b2bCampaignsSaveLevel() { return endpoint+'/b2b/campaigns/save/level' }
    static b2bCampaignsSaveProfile() { return endpoint+'/b2b/campaigns/' }
    static b2bCampaignsDeleteProfile() { return endpoint+'/b2b/campaigns/' }
    static b2bCampaignsSaveClient() { return endpoint+'/b2b/campaigns/' }
    static b2bCampaignsDeleteClient() { return endpoint+'/b2b/campaigns/' }
    static b2bCampaignsDeleteProduct() { return endpoint+'/campaigns/' }
    static b2bCampaignsGetProducts() { return endpoint+'/campaigns/products' }
    static b2bCampaignsAddProducts() { return endpoint+'/campaigns/' }


    //Clients
    static client() { return endpoint+'/clients' }
    static clientUpdateDiscount() { return endpoint+'/clients/updateDiscount' }
    static clientUpdateAddress() { return endpoint+'/clients/updateAddress' }
    static clientInsertAddress() { return endpoint+'/clients/insertAddress' }
    static clientDeleteAddress() { return endpoint+'/clients/deleteAddress' }
    static clientUpdateNotes() { return endpoint+'/clients/updateNotes' }
    static clientUpdate() { return endpoint+'/clients/update' }
    static clients() { return endpoint+'/clients' }
    static clientsDirectDebits() { return endpoint+'/clients/debits' }

    //Reports
    static reportsSalesZone() { return endpoint+'/reports/sales/zone' }
    static reportsSalesZoneClient() { return endpoint+'/reports/sales/zoneClient' }
    static reportsSalesClientYear() { return endpoint+'/reports/sales/client/year' }
    static reportsSalesClientBrand() { return endpoint+'/reports/sales/client/brand' }
    static reportsSalesByBrand() { return endpoint+'/reports/sales/brand' }
    static reportsSalesBySection() { return endpoint+'/reports/sales/section' }
    static reportsCRMInteractions() { return endpoint+'/reports/interactions' }

    //Orders
    static orders() { return endpoint+'/orders' }
    static ordersBikes() { return endpoint+'/orders/bikes' }
    static ordersToday() { return endpoint+'/orders/today' }
    static ordersAll() { return endpoint+'/orders/all' }
    static ordersProformas() { return endpoint+'/orders/proformas' }
    static ordersShipped() { return endpoint+'/orders/shipped' }
    static orderShippedArchive() { return endpoint+'/orders/shipped/archive' }
    static orderTransferBikePlanet() { return endpoint+'/orders/transfer/bikePlanet' }
    static orderWaitingShipping() { return endpoint+'/orders/waitingShipping' }
    static boxesShipping() { return endpoint+'/orders/shipping-info/shipping-boxes' }
    static orderHideDoc() { return endpoint+'/orders/hideDoc' }
    static orderRecoverDoc() { return endpoint+'/orders/recoverDoc' }
    static orderTrackingNumber() { return endpoint+'/orders/trackingNumber' }
    static orderDetailClient() { return endpoint+'/orders/getDetailClient' }
    static orderCreateShippingLabel() { return endpoint+'/orders/createShippingLabel' }
    static orderDetail() { return endpoint+'/orders/detail' }
    static orderStocks() { return endpoint+'/orders/stocks' }
    static orderPrintManifestoSEUR() { return endpoint+'/orders/manifest' }
    static orderPrintLabelDeporvillage() { return endpoint+'/order/printLabelDeporvillage' }
    static orderPrintLabelEAN() { return endpoint+'/order/printLabelEAN' }
    static orderHistoricShipped() { return endpoint+'/orders/historicShipped' }
    static orderUnmarkAsShipped() { return endpoint+'/orders/unmarkAsShipped' }
    static orderPDFFile() { return endpoint+'/orders/getPDFFile' }
    static orderDownloadPDFFile() { return endpoint+'/orders/openPDFFile' }
    static edifactGlobal() { return endpoint+'/edifact' }
    static orderHistoric() { return endpoint+'/orders/ordersHistoric' }
    static ordersShippingCostsAreas() { return endpoint+'/orders/shippingCosts/areas' }
    static ordersShippingCostsSaveArea() { return endpoint+'/orders/shippingCosts/saveArea' }
    static ordersShippingCosts() { return endpoint+'/orders/shippingCosts' }
    static ordersShippingCostsZones() { return endpoint+'/orders/shippingCosts/zones' }
    static ordersProformaLines() { return endpoint+'/orders/proformas/linesProformas' }
    // static ordersPickups() { return endpoint+'/orders/pickups' }
    // static orderUndeliveredPickups () {return endpoint +'orders/undeliveredPickups' }

    //Payments
    static paymentsHistoric() { return endpoint+'/payments/historic'}
    static paymentsUploadXlsSeur() { return endpoint+'/payments/upload/xls/seur'}
    // static paymentsUploadXlsCheques() { return endpoint+'/payments/upload/xls/cheques'}
    // static paymentsMakeReceipt() { return endpoint+'/payments/make/receipt'}
    // static paymentsPendentes() { return endpoint+'/payments/pendentes'}
    // static paymentsAddDocument() { return endpoint+'/payments/add'}
    static paymentsAddEntidade() { return endpoint+'/payments/entidade'}

    //DropShipping
    static dropShippingOrders() { return endpoint+'/dropshipping/orders' }
    static dropShippingCreateInvoice() { return endpoint+'/dropshipping/createInvoice' }
    static dropShippingCancelInvoice() { return endpoint+'/dropshipping/cancelInvoice' }
    static dropShippingGetPriceCourier() { return endpoint+'/dropshipping/getPriceCourier' }
    static dropShippingCreateShipping() { return endpoint+'/dropshipping/createShipping' }
    static dropShippingHistoricDocs() { return endpoint+'/dropshipping/historicDocs' }
    static dropShippingDownloadReturnLabel() { return endpoint+'/dropshipping/returnLabel' }
    // static dropShippingDispatch = endpoint+'/dropshipping/dispatch'

    //Debits
    static debits() { return endpoint+'/debits' }
    static debitsChange() { return endpoint+'/debits/change' }
    static debitsAdd() { return endpoint+'/debits/add' }
    static debitsEdit() { return endpoint+'/debits/edit'}
    static debitsLines() { return endpoint+'/debits/lines' }
    static debitsLinesAdd() { return endpoint+'/debits/lines/add' }
    static debitsReceipts() { return endpoint+'/debits/receipts' }
    static debitsXml() { return endpoint+'/debits/xml' }
    static debitsDownload() { return endpoint+'/debits/download' }
    static debitsDetail() { return endpoint+'/debits/detail' }
    static debitsDeleteLine() { return endpoint+'/debits/lines/delete' }
    static debitsBank() { return endpoint+'/debits/bank' }
    static debitsSendEmail() { return endpoint+'/debits/email/debit' }
    static debitsUploadXML() { return endpoint+'/debits/upload/xml' }
    static debitsProcessXmlLine() { return endpoint+'/debits/process/line' }

    static debitsSendEmailEstorno() { return endpoint+'/debits/sendEmailEstorno' }
    static debitsHistoric() { return endpoint+'/debits/historic' }

    //Mandates
    static mandates() { return endpoint+'/mandates' }
    static mandatesNew() { return endpoint+'/mandates/new' }
    static mandatesSend() { return endpoint+'/mandates/send' }

    //Dashboard
    static dashboardWidgetTableB2B() { return endpoint+'/dashboard/widget/tableB2B' }
    static dashboardWidgetTableFox() { return endpoint+'/dashboard/widget/tableFox' }
    static dashboardWidgetPendingOrders() { return endpoint+'/dashboard/widget/pendingOrders' }
    static dashboardWidgetUpdatePendingOrder() { return endpoint+'/dashboard/widget/pendingOrders/update' }
    static dashboardWidgetPendingReceipts() { return endpoint+'/dashboard/widget/pendingReceipts' }
    static dashboardWidgetLastSales() { return endpoint+'/dashboard/widget/lastSales' }
    static dashboardWidgetLastOrders() { return endpoint+'/dashboard/widget/lastOrders' }

    //Commercial
    static commercialReportsApp() { return endpoint+'/commercial/reportsApp' }
}

export default api
