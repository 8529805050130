<template>
    <div>
        <v-card tile min-height="300">
            <v-card-text>
                <v-row>
                    <v-col class="text-center">
                        <v-btn-toggle mandatory color="rgba(192, 13, 13, 1)" dense>
                            <v-btn @click="updateLanguage('PT')">
                                <flag iso="pt" v-bind:squared=false></flag>&nbsp;PT
                                <v-icon
                                        color="error"
                                        v-if="description.Nome_PT === '' || description.Descricao_PT === '' || description.NomePRI_PT === ''"
                                >warning
                                </v-icon>
                            </v-btn>
                            <v-btn @click="updateLanguage('ES')">
                                <flag iso="es" v-bind:squared=false></flag>&nbsp;ES
                                <v-icon
                                        color="error"
                                        v-if="description.Nome_ES === '' || description.Descricao_ES === '' || description.NomePRI_ES === ''"
                                >warning
                                </v-icon>
                            </v-btn>
                            <v-btn @click="updateLanguage('EN')">
                                <flag iso="gb" v-bind:squared=false></flag>&nbsp;EN
                                <v-icon
                                        color="error"
                                        v-if="description.Nome_EN === '' || description.Descricao_EN === '' || description.NomePRI_EN === ''"
                                >warning
                                </v-icon>
                            </v-btn>
                            <v-btn @click="updateLanguage('FR')">
                                <flag iso="fr" v-bind:squared=false></flag>&nbsp;FR
                                <v-icon
                                        color="error"
                                        v-if="description.Nome_FR === '' || description.Descricao_FR === '' || description.NomePRI_FR === ''"
                                >warning
                                </v-icon>
                            </v-btn>
                            <v-btn @click="updateLanguage('IT')">
                                <flag iso="it" v-bind:squared=false></flag>&nbsp;IT
                                <v-icon
                                        color="error"
                                        v-if="description.Nome_IT === '' || description.Descricao_IT === '' || description.NomePRI_IT === ''"
                                >warning
                                </v-icon>
                            </v-btn>
                            <v-btn @click="updateLanguage('DE')">
                                <flag iso="de" v-bind:squared=false></flag>&nbsp;DE
                                <v-icon
                                        color="error"
                                        v-if="description.Nome_DE === '' || description.Descricao_DE === '' || description.NomePRI_DE === ''"
                                >warning
                                </v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-form ref="formDescription">
                    <v-row>
                        <v-col>
                            <v-text-field
                                    v-model="inputDescriptionB2B.primavera"
                                    counter="50"
                                    :rules="[rules.length50, rules.required]"
                                    :label="$t('name')+' Fatura'"
                            ></v-text-field>
                            <v-text-field
                                    v-model="inputDescriptionB2B.titulo"
                                    counter="255"
                                    :rules="[rules.length255, rules.required]"
                                    :label="'B2B - '+$t('description')"
                            ></v-text-field>
                            <v-text-field
                                    v-model="inputDescriptionB2B.titulo2"
                                    counter="255"
                                    :rules="[rules.length255]"
                                    :label="'B2B '+$t('description')+' 2'"
                            ></v-text-field>
                            <label>B2B - Características</label>
                            <editor
                                    apiKey="pmmncfsqqm0oln114ff7w3t2w21ugzvr2aumwlrt0e0q3luh"
                                    v-model="inputDescriptionB2B.caracteristicas"
                                    style="height: 500px"
                                    :init="{plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste imagetools wordcount'}"
                            ></editor>
                        </v-col>
                        <v-col>
                            <v-text-field
                                    v-model="inputDescriptionWebsite.titulo"
                                    counter="255"
                                    :rules="[rules.length255]"
                                    :label="'WEB - '+$t('description')"
                            ></v-text-field>
                            <label>WEB - Características</label>
<!--                            <v-textarea-->
<!--                                    v-model="inputDescriptionWebsite.caracteristicas"-->
<!--                                    auto-grow-->
<!--                                    solo-->
<!--                            ></v-textarea>-->
                            <editor
                                    apiKey="pmmncfsqqm0oln114ff7w3t2w21ugzvr2aumwlrt0e0q3luh"
                                    v-model="inputDescriptionWebsite.caracteristicas"
                                    style="height: 500px"
                                    :init="{plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste imagetools wordcount'}"
                            ></editor>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions v-if="haveDescriptionChanged">
                <v-row class="mb-0">
                    <v-col class="text-right">
                        <v-btn color="success" small @click="saveDescription()" v-if="writePermission('products/product')">
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="dialogChangeLanguage" persistent max-width="300">
                <staff-dialog :title="'Guardar alterações '+language" :titleColor="''">
                    <template slot="content">
                        Deseja guardar as alterações efetuadas?
                    </template>
                    <template slot="action">
                        <v-btn color="error" @click="notSaveDescription()">{{ $t('no') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveDescription()">{{ $t('yes') }}</v-btn>
                    </template>
                </staff-dialog>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';

    export default {
        components: {
            'editor': Editor
        },
        props: {
            productId: String,
        },
        data: function () {
            return {
                language: 'PT',
                languageChange: '',
                description: [],
                inputDescriptionB2B: {
                    primavera: '',
                    titulo: '',
                    titulo2: '',
                    caracteristicas: ''
                },
                inputDescriptionWebsite: {
                    titulo: '',
                    caracteristicas: ''
                },
                rules: {
                    required: value => !!value || value === '0' || 'Este campo é obrigatório.',
                    length50: value => (value || '').length <= 50 || 'Max 50 caracteres',
                    length255: value => (value || '').length <= 255 || 'Max 255 caracteres'
                },
                dialogChangeLanguage: false
            }
        },
        mounted() {
            this.getProduct()
        },
        methods: {
            getProduct() {
                let request = {
                    url: api.products() + '/' + this.productId + '/description'
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.description = responseData.description
                            this.setDescription()
                        }
                    })
                    .catch(response => {

                    })
            },
            setDescription() {
                switch (this.language) {
                    case "PT":
                        this.inputDescriptionB2B.primavera = this.description.NomePRI_PT
                        this.inputDescriptionB2B.titulo = this.description.Nome_PT
                        this.inputDescriptionB2B.titulo2 = this.description.Nome2_PT
                        this.inputDescriptionB2B.caracteristicas = this.description.Descricao_PT
                        this.inputDescriptionWebsite.titulo = this.description.WEB_Nome_PT
                        this.inputDescriptionWebsite.caracteristicas = this.description.WEB_Descricao_PT
                        break
                    case "ES":
                        this.inputDescriptionB2B.primavera = this.description.NomePRI_ES
                        this.inputDescriptionB2B.titulo = this.description.Nome_ES
                        this.inputDescriptionB2B.titulo2 = this.description.Nome2_ES
                        this.inputDescriptionB2B.caracteristicas = this.description.Descricao_ES
                        this.inputDescriptionWebsite.titulo = this.description.WEB_Nome_ES
                        this.inputDescriptionWebsite.caracteristicas = this.description.WEB_Descricao_ES
                        break
                    case "EN":
                        this.inputDescriptionB2B.primavera = this.description.NomePRI_EN
                        this.inputDescriptionB2B.titulo = this.description.Nome_EN
                        this.inputDescriptionB2B.titulo2 = this.description.Nome2_EN
                        this.inputDescriptionB2B.caracteristicas = this.description.Descricao_EN
                        this.inputDescriptionWebsite.titulo = this.description.WEB_Nome_EN
                        this.inputDescriptionWebsite.caracteristicas = this.description.WEB_Descricao_EN
                        break
                    case "FR":
                        this.inputDescriptionB2B.primavera = this.description.NomePRI_FR
                        this.inputDescriptionB2B.titulo = this.description.Nome_FR
                        this.inputDescriptionB2B.titulo2 = this.description.Nome2_FR
                        this.inputDescriptionB2B.caracteristicas = this.description.Descricao_FR
                        this.inputDescriptionWebsite.titulo = this.description.WEB_Nome_FR
                        this.inputDescriptionWebsite.caracteristicas = this.description.WEB_Descricao_FR
                        break
                    case "IT":
                        this.inputDescriptionB2B.primavera = this.description.NomePRI_IT
                        this.inputDescriptionB2B.titulo = this.description.Nome_IT
                        this.inputDescriptionB2B.titulo2 = this.description.Nome2_IT
                        this.inputDescriptionB2B.caracteristicas = this.description.Descricao_IT
                        this.inputDescriptionWebsite.titulo = this.description.WEB_Nome_IT
                        this.inputDescriptionWebsite.caracteristicas = this.description.WEB_Descricao_IT
                        break
                    case "DE":
                        this.inputDescriptionB2B.primavera = this.description.NomePRI_DE
                        this.inputDescriptionB2B.titulo = this.description.Nome_DE
                        this.inputDescriptionB2B.titulo2 = this.description.Nome2_DE
                        this.inputDescriptionB2B.caracteristicas = this.description.Descricao_DE
                        this.inputDescriptionWebsite.titulo = this.description.WEB_Nome_DE
                        this.inputDescriptionWebsite.caracteristicas = this.description.WEB_Descricao_DE
                        break
                    default:
                        this.inputDescriptionB2B.primavera = 'IDIOMA INVÁLIDO';
                        this.inputDescriptionB2B.titulo = 'IDIOMA INVÁLIDO!';
                        this.inputDescriptionB2B.titulo2 = 'IDIOMA INVÁLIDO!';
                        this.inputDescriptionB2B.caracteristicas = 'IDIOMA INVÁLIDO!';
                        this.inputDescriptionWebsite.titulo = 'IDIOMA INVÁLIDO!';
                        this.inputDescriptionWebsite.caracteristicas = 'IDIOMA INVÁLIDO!';
                        break
                }
            },
            updateLanguage(language) {
                if (this.haveDescriptionChanged) {
                    this.languageChange = language
                    this.dialogChangeLanguage = true
                } else {
                    this.language = language
                    this.languageChange = language
                    this.setDescription()
                }
            },
            saveDescription() {
                if (this.$refs.formDescription.validate()) {
                    let request = {
                        url: api.products() + '/' + this.productId + '/description',
                        data: {
                            language: this.language,
                            descriptionB2B: this.inputDescriptionB2B,
                            descriptionWEB: this.inputDescriptionWebsite
                        }
                    }

                    ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Descrições alteradas com sucesso!',
                                    type: 'success'
                                })
                                if (this.languageChange !== '') {
                                    this.language = this.languageChange
                                }
                                this.getProduct()
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao alterar descrições!',
                                    type: 'error'
                                })
                            }
                            this.dialogChangeLanguage = false
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro servidor!',
                                type: 'error'
                            })
                            this.dialogChangeLanguage = false
                        })
                }
            },
            notSaveDescription() {
                this.language = this.languageChange
                this.getProduct()
                this.dialogChangeLanguage = false
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            },
        },
        computed: {
            haveDescriptionChanged() {
                if (!this.writePermission('products/product')) {
                    return false;
                }
                let changed = false
                switch (this.language) {
                    case "PT":
                        if (this.inputDescriptionB2B.titulo !== this.description.Nome_PT) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.titulo2 !== this.description.Nome2_PT) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.caracteristicas !== this.description.Descricao_PT) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.primavera !== this.description.NomePRI_PT) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.titulo !== this.description.WEB_Nome_PT) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.caracteristicas !== this.description.WEB_Descricao_PT) {
                            changed = true
                        }
                        break
                    case "ES":
                        if (this.inputDescriptionB2B.titulo !== this.description.Nome_ES) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.titulo2 !== this.description.Nome2_ES) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.caracteristicas !== this.description.Descricao_ES) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.primavera !== this.description.NomePRI_ES) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.titulo !== this.description.WEB_Nome_ES) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.caracteristicas !== this.description.WEB_Descricao_ES) {
                            changed = true
                        }
                        break
                    case "EN":
                        if (this.inputDescriptionB2B.titulo !== this.description.Nome_EN) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.titulo2 !== this.description.Nome2_EN) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.caracteristicas !== this.description.Descricao_EN) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.primavera !== this.description.NomePRI_EN) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.titulo !== this.description.WEB_Nome_EN) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.caracteristicas !== this.description.WEB_Descricao_EN) {
                            changed = true
                        }
                        break
                    case "FR":
                        if (this.inputDescriptionB2B.titulo !== this.description.Nome_FR) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.titulo2 !== this.description.Nome2_FR) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.caracteristicas !== this.description.Descricao_FR) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.primavera !== this.description.NomePRI_FR) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.titulo !== this.description.WEB_Nome_FR) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.caracteristicas !== this.description.WEB_Descricao_FR) {
                            changed = true
                        }
                        break
                    case "IT":
                        if (this.inputDescriptionB2B.titulo !== this.description.Nome_IT) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.titulo2 !== this.description.Nome2_IT) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.caracteristicas !== this.description.Descricao_IT) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.primavera !== this.description.NomePRI_IT) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.titulo !== this.description.WEB_Nome_IT) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.caracteristicas !== this.description.WEB_Descricao_IT) {
                            changed = true
                        }
                        break
                    case "DE":
                        if (this.inputDescriptionB2B.titulo !== this.description.Nome_DE) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.titulo2 !== this.description.Nome2_DE) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.caracteristicas !== this.description.Descricao_DE) {
                            changed = true
                        }
                        if (this.inputDescriptionB2B.primavera !== this.description.NomePRI_DE) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.titulo !== this.description.WEB_Nome_DE) {
                            changed = true
                        }
                        if (this.inputDescriptionWebsite.caracteristicas !== this.description.WEB_Descricao_DE) {
                            changed = true
                        }
                        break
                }
                return changed
            },
            validateErrorLanguage() {
                return this.description.Nome_PT === '' || this.description.Descricao_PT === '' || this.description.NomePRI_PT === ''
                    || this.description.Nome_ES === '' || this.description.Descricao_ES === '' || this.description.NomePRI_ES === ''
                    || this.description.Nome_EN === '' || this.description.Descricao_EN === '' || this.description.NomePRI_EN === ''
                    || this.description.Nome_FR === '' || this.description.Descricao_FR === '' || this.description.NomePRI_FR === ''
                    || this.description.Nome_IT === '' || this.description.Descricao_IT === '' || this.description.NomePRI_IT === ''
                    || this.description.Nome_DE === '' || this.description.Descricao_DE === '' || this.description.NomePRI_DE === '';
            }
        }
    }
</script>
