import { render, staticRenderFns } from "./Stocks.vue?vue&type=template&id=b8e3787c&"
import script from "./Stocks.vue?vue&type=script&lang=js&"
export * from "./Stocks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Stocks.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=vue-virtual-scroller%2Fdist%2Fvue-virtual-scroller.css"
if (typeof block0 === 'function') block0(component)

export default component.exports