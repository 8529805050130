import Vue from 'vue'
import Router from 'vue-router'

// VIEWS
import Dashboard from '../views/Dashboard'
import Login from "../views/Home";

// COMPONENTS
//Debug
import DebugPage from "../components/Debug"

//Administration
import AdministrationUsers from "../components/Administration/Users"
import AdministrationPermission from "../components/Administration/Permission"
import AdministrationProfile from "../components/Administration/Profile"
import AdministrationLogin from "../components/Administration/Logins"

//Products
import ProductsList from "../components/Products/ListProducts"
import Product from "../components/Products/Product"
import Images from "../components/Products/Images"
import Families from "../components/Products/Families"
import SubFamilies from "../components/Products/SubFamilies"
import BrandsDiscounts from "../components/Products/BrandsDiscounts";
import BrandDiscountsLevels from "../components/Products/BrandDiscountsLevels";
import Ecovalor from "../components/Products/Ecovalor";
import ProductsBikes from "../components/Products/ListBikes";
import Monos from "../components/Products/Monos";
import CreateProduct from "@/components/Products/CreateProduct";

import ProductProblems from "@/components/Products/Problems/ProductProblems";
import WarehouseTransfer from "../components/Products/WarehouseTransfer";

import ListProductsEanCheck from "@/components/Products/ListProductsEanCheck";

import ExpiringLotes from "@/components/Products/ExpiringLotes.vue";
import Recomended from "@/components/Products/Recomended";
//B2B
import B2BLogins from "../components/B2B/Logins"
import B2BBanners from "../components/B2B/Banners"
import B2BSCPrice from "../components/B2B/SCPrice"
import Campaigns from "../components/B2B/Campaigns"
import Campaign from "../components/B2B/Campaign"
import B2BCupons from "../components/B2B/Cupons"
import B2BMiniBanners from "@/components/B2B/MiniBanners";
import BannersB2B from "@/components/B2B/BannersB2B.vue";
import BannerID from "@/components/B2B/BannerID.vue";
import BannerTypes from "@/components/B2B/BannerTypes.vue";
import BannerLocation from "@/components/B2B/BannerLocation.vue";
import Campaigns2 from "../components/Campaigns/Campaigns"
import Campaign2 from "../components/Campaigns/Campaign"

// Courses
import Courses from "../components/Courses/Courses"
import CoursesPlaces from "../components/Courses/CoursesPlaces"
import CoursesLevels from "../components/Courses/CoursesLevels"

//Clients
import Clients from "../components/Clients/Clients"
import ClientsDirectDebits from "../components/Clients/DirectDebits"
import Client from "../components/Clients/Client"
import ClientsProblem from "../components/Clients/ClientsProblem"
import NewClient from "@/components/Clients/NewClient";
import ClientsRegistration from "@/components/Clients/ClientsRegistration.vue";
import RegistrationDetails from "@/components/Clients/RegistrationDetails.vue";
import ClientV2 from "../components/Clients/ClientV2.vue"

//Reports
import ReportsSalesZone from "../components/Reports/SalesZone"
import ReportsSalesZoneClient from "../components/Reports/SalesZoneClient"
import ReportsSalesClientYear from "../components/Reports/SalesClientYear"
import ReportsSalesClientBrand from "../components/Reports/SalesClientBrand"
import ReportsSalesBrand from "../components/Reports/SalesByBrand"
import ReportsCrm from "../components/ReportsAdmin/Interactions.vue"

//ReportsAdmin
import ReportsAdminSalesSection from "../components/ReportsAdmin/SalesBySection"

//Orders

import EdifactDetails from "../components/Orders/EdifactDetails";
import Edifact from "../components/Orders/Edifact"
import Orders from "../components/Orders/Orders"
import OrdersBikes from "../components/Orders/OrdersBikes"
import OrdersAvailability from "@/components/Orders/OrdersAvailability";
import Proformas from "../components/Orders/ProFormas"
import Shipping from "../components/Orders/Shipping"
import ShippedPending from "../components/Orders/ShippedPending"
import ShippedHistory from "../components/Orders/ShippedHistory"
import OrdersHistoric from "../components/Orders/OrdersHistoric";

import ShippingCost from "../components/Orders/ShippingCost";
import PickList from "../components/Orders/PickList";
import Pick from "../components/Orders/Pick";
import PickHistoryList from "../components/Orders/PickHistoryList";
import PickHistoryDetail from "../components/Orders/PickHistoryDetail";
import PickView from "@/components/Orders/PickView";
import Pickups from "../components/Orders/Pickups";

//BikePlanet
import BikePlanetOrders from "../components/Online/BikePlanetOrders"

//Bicimax.com -- Prestashop
import BicimaxProducts from "@/components/Bicimax-com/products";
import BicimaxOrders from "@/components/Bicimax-com/orders";
import BicimaxBanners from "@/components/Bicimax-com/banners";

//Nozama
import NozamaTemplates from "../components/Online/NozamaTemplates";
import NozamaOrders from "@/components/Online/NozamaOrders";
import NozamaOrderDetail from "@/components/Online/NozamaOrderDetail";
import NozamaStats from "@/components/Online/NozamaStats";

//Debits
import Debits from "../components/Debits/Debits"
import DebitsAddLines from "../components/Debits/AddLines"
import DebitsDetail from "../components/Debits/DetailDebit"
import DebitsMandates from "../components/Debits/Mandates"
import HistoricDebits from "../components/Debits/HistoricDebits";

//Marketing
import Mailchimp from "../components/Marketing/Mailchimp";

//Commercial
import Sales from "../components/Commercial/Sales";
import ReportsApp from "../components/Commercial/ReportsApp";
import ReportsAppDetail from "../components/Commercial/ReportsAppDetail";

//Brands
import Stocks from "../components/Brands/Stocks";
//Problems
import WrongInfo from "../components/Brands/WrongInfo";
//import Bikes from "../components/Brands/Bikes";
import ImporterECF from "../components/Brands/ImporterECF";

//Primavera
import Intrastat from "../components/Primavera/Intrastat";
import Profiles from "../components/Primavera/Profiles";
import Invoice from "../components/Orders/Invoice";
import IntrastatPurchases from "../components/Primavera/IntrastatPurchases";


//Purchases
import Purchases from "@/components/Purchases/Purchases";
import PurchasesDetail from "@/components/Purchases/PurchasesDetail";
import AddPurchaseLines from "@/components/Purchases/AddPurchaseLines";
import Purchase from "@/components/Purchases/Purchase";
import PurchasesDetailUpdate from "@/components/Purchases/PurchasesDetailUpdate";
import PickPurchase from "@/components/Purchases/Pick";
import PickListPurchase from "@/components/Purchases/PickList";
import PurchasesOutdated from "@/components/Purchases/PurchasesOutdated";
import OpenOrders from "@/components/Purchases/OpenOrders";
import OpenOrderDetail from "@/components/Purchases/OpenOrderDetail";
import OpenOrderSupplierDetails from "@/components/Purchases/OpenOrderSupplierDetails";

//Accounts
import Pending from "@/components/Accounts/Pending";
import Payments from "../components/Accounts/Payments";
import Credits from "../components/Accounts/Credits";
import Cosec from "@/components/Accounts/Cosec";

//Models
import User from "../models/User"

//VFAs
import VFAs from "@/components/Purchases/VFAs";
import VFAsDetail from "@/components/Purchases/VFAsDetail";

//Online
import ProductsPlanet from "@/components/Online/ProductsPlanet";
import DocumentsPlanet from "@/components/Online/DocumentsPlanet";
import CreditsOffmax from "@/components/Offmax/CreditsOffmax";
import Logs from "@/components/Administration/Logs";
import OrdersAmazon from "@/components/Bikeplanet/OrdersAmazon";
import GiftInvoices from "@/components/Accounts/GiftInvoices";
import CampaignsHistoric from "@/components/B2B/CampaignsHistoric";
import OrdersHistory from "@/components/Bikeplanet/OrdersHistory";
import AmazonListing from "@/components/Bikeplanet/AmazonListing";
import OrdersUpdateDate from "@/components/Orders/OrdersUpdateDate";
import OrdersCostsAmazon from "@/components/Bikeplanet/OrdersCostsAmazon";
import AmazonCommingling from "@/components/Bikeplanet/AmazonCommingling";
import Receipts from "@/components/Bikeplanet/Receipts";
import PdfsPurchases from "@/components/Accounts/PdfsPurchases";
import PdfsPurchasesBP from "@/components/Bikeplanet/PdfsPurchasesBP.vue";
import Forms from "@/components/Formularios/Forms.vue";
import Form from "@/components/Formularios/Form.vue";
import FormStats from "@/components/Formularios/FormStats.vue";
import ShippingV2 from "@/components/Orders/ShippingV2.vue";


Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'is-active',
    routes: [
        {
            path: '/debug',
            name: 'debug',
            component: DebugPage
        },
        {
            path: '/login',
            component: Login
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/logins',
            name: 'b2b/logins',
            component: B2BLogins,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/banners',
            name: 'b2b/banners',
            component: B2BBanners,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/miniBanners',
            name: 'b2b/miniBanners',
            component: B2BMiniBanners,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/campaigns/historic',
            name: 'b2b/campaigns/historic',
            component: CampaignsHistoric,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/campaigns',
            name: 'b2b/campaigns',
            component: Campaigns,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/campaigns/:id',
            name: 'b2b/campaigns/campaign',
            component: Campaign,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/campaigns2',
            name: 'b2b/campaigns2',
            component: Campaigns2,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/campaigns2/:id',
            name: 'b2b/campaigns2/campaign',
            component: Campaign2,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/priceSC',
            name: 'b2b/priceSC',
            component: B2BSCPrice,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/coupons',
            name: 'b2b/coupons',
            component: B2BCupons,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/BannersB2B',
            name: 'b2b/BannersB2B',
            component: BannersB2B,
            meta: {layout: "default"}
        },{
            path: '/b2b/BannersB2B/:id',
            name: 'b2b/BannersB2B/banner',
            component: BannerID,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/BannerTypes',
            name: 'b2b/BannerTypes',
            component: BannerTypes,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/BannerLocation',
            name: 'b2b/BannerLocation',
            component: BannerLocation,
            meta: {layout: "default"}
        },
        {
            path: '/b2b/recommended-products',
            name: 'b2b/recommended-products',
            component: Recomended,
            meta: {layout: "default"}
        },
        {
            path: '/products/expiringLotes',
            name: 'products/expiringLotes',
            component: ExpiringLotes,
            meta: {layout: "default"}
        },
        {
            path: '/bikeplanet/invoice-amazon',
            name: 'bikeplanet/invoice-amazon',
            component: OrdersAmazon,
            meta: {layout: "default"}
        },
        {
            path: '/bikeplanet/amazon-commingling',
            name: 'bikeplanet/amazon-commingling',
            component: AmazonCommingling,
            meta: {layout: "default"}
        },
        {
            path: '/bikeplanet/history-amazon',
            name: 'bikeplanet/history-amazon',
            component: OrdersHistory,
            meta: {layout: "default"}
        },
        {
            path: '/bikeplanet/costs-amazon',
            name: 'bikeplanet/costs-amazon',
            component: OrdersCostsAmazon,
            meta: {layout: "default"}
        },
        {
            path: '/bikeplanet/amazon-listing',
            name: 'bikeplanet/amazon-listing',
            component: AmazonListing,
            meta: {layout: "default"}
        },
        {
            path: '/forms',
            name: 'forms',
            component: Forms,
            meta: {layout: "default"}
        },
        {
            path: '/forms/:id',
            name: 'forms/form',
            component: Form,
            meta: {layout: "default"}
        },
        {
            path: '/forms/:id/stats',
            name: 'forms/form/stats',
            component: FormStats,
            meta: {layout: "default"}
        },
        {
            path: '/online/bikeplanet/orders',
            name: 'online/bikeplanet/orders',
            component: BikePlanetOrders,
            meta: {layout: "default"}
        },
        {
            path: '/bikeplanet/receipts',
            name: 'bikeplanet/receipts',
            component: Receipts,
            meta: {layout: "default"}
        },
        {
            path: '/online/products',
            name: 'online/products',
            component: ProductsPlanet,
            meta: {layout: "default"}
        },
        {
            path: '/bicimax-com/products',
            name: 'bicimax-com/products',
            component: BicimaxProducts,
            meta: {layout: "default"}
        },
        {
            path: '/bicimax-com/orders',
            name: 'bicimax-com/orders',
            component: BicimaxOrders,
            meta: {layout: "default"}
        },
        {
            path: '/bicimax-com/banners',
            name: 'bicimax-com/banners',
            component: BicimaxBanners,
            meta: {layout: "default"}
        },
        {
            path: '/online/nozama/templates',
            name: 'online/nozama/templates',
            component: NozamaTemplates,
            meta: {layout: "default"}
        },
        {
            path: '/online/nozama/orders',
            name: 'online/nozama/orders',
            component: NozamaOrders,
            meta: {layout: "default"}
        },
        {
            path: '/online/nozama/orders/:id',
            name: 'online/nozama/orders/order',
            component: NozamaOrderDetail,
            meta: {layout: "default"}
        },
        {
            path: '/online/nozama/stats',
            name: 'online/nozama/stats',
            component: NozamaStats,
            meta: {layout: "default"}
        },
        {
            path: '/online/docs/planet',
            name: 'online/docs/planet',
            component: DocumentsPlanet,
            meta: {layout: "default"}
        },
        {
            path: '/products/products',
            name: 'products/products',
            component: ProductsList,
            meta: {layout: "default"}
        },
        {
            path: '/products/products/:id',
            name: 'products/products/product',
            component: Product,
            meta: {layout: "default"}
        },
        {
            path: '/products/bikes',
            name: 'products/bikes',
            component: ProductsBikes,
            meta: {layout: "default"}
        },
        {
            path: '/products/families',
            name: 'products/families',
            component: Families,
            meta: {layout: "default"}
        },
        {
            path: '/products/subFamilies/:family',
            name: 'products/subFamilies',
            component: SubFamilies,
            meta: {layout: "default"}
        },
        {
            path: '/products/warehouse',
            name: 'products/warehouse',
            component: WarehouseTransfer,
            meta: {layout: "default"}
        },
        {
            path: '/products/brandsDiscount',
            name: 'products/brandsDiscount',
            component: BrandsDiscounts,
            meta: {layout: "default"}
        },
        {
            path: '/products/brandsDiscount/:brand',
            name: 'products/brandsDiscountLevels',
            component: BrandDiscountsLevels,
            meta: {layout: "default"}
        },
        {
            path: '/products/ecovalor',
            name: 'products/ecovalor',
            component: Ecovalor,
            meta: {layout: "default"}
        },
        {
            path: '/products/images',
            name: 'products/images',
            component: Images,
            meta: {layout: "default"}
        },
        {
            path: '/products/monos',
            name: 'products/monos',
            component: Monos,
            meta: {layout: "default"}
        },
        {
            path: '/products/create',
            name: 'products/create',
            component: CreateProduct,
            meta: {layout: "default"}
        },
        {
            path: '/products/problems/:family',
            name: 'products/problems',
            component: ProductProblems,
            meta: {layout: "default"}
        },
        {
            path: '/products/ean13',
            name: 'products/ean13',
            component: ListProductsEanCheck,
            meta: {layout: "default"}
        },
        {
            path: '/brands/stock',
            name: 'brands/stock',
            component: Stocks,
            meta: {layout: "default"}
        },
        {
            path: '/brands/problems',
            name: 'brands/problems',
            component: WrongInfo,
            meta: {layout: "default"}
        },
        /*{
            path: '/brands/bikes',
            name: 'brands/bikes',
            component: Bikes,
            meta: {layout: "default"}
        },*/
        {
            path: '/brands/importECF',
            name: 'brands/importECF',
            component: ImporterECF,
            meta: {layout: "default"}
        },
        {
            path: '/clients/clients',
            name: 'clients/clients',
            component: Clients,
            meta: {layout: "default"}
        },
        {
            path: '/clients/clientsProblem',
            name: 'clients/clientsProblem',
            component: ClientsProblem,
            meta: {layout: "default"}
        },
        {
            path: '/clients/clients/new',
            name: 'clients/clients/new',
            component: NewClient,
            meta: {layout: "default"}
        },
        {
            path: '/clients/clientsRegistration',
            name: 'clients/clientsRegistration',
            component: ClientsRegistration,
            meta: {layout: "default"}
        },
        {
            path: '/clients/clientsRegistration/:id',
            name: 'clients/clientsRegistration/registration',
            component: RegistrationDetails,
            meta: {layout: "default"}
        },
        {
            path: '/clients/clients/:id',
            name: 'clients/clients/client',
            component: Client,
            meta: {layout: "default"}
        },
        {
            path: '/clients/clientsV2/:id',
            name: 'clients/clients/client',
            component: ClientV2,
            meta: {layout: "default"}
        },
        {
            path: '/reports/reportsApp/:id',
            name: 'clients/clients/reports',
            component: ReportsAppDetail,
            meta: {layout: "default"}
        },
        {
            path: '/clients/debits',
            name: 'clients/debits',
            component: ClientsDirectDebits,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/purchases',
            name: 'purchases/purchases',
            component: Purchases,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/details',
            name: 'purchases/details',
            component: OpenOrderSupplierDetails,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/pick',
            name: 'purchases/pick',
            component: PickListPurchase,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/pick/:id',
            name: 'purchases/pick/pick',
            component: PickPurchase,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/pick/invoice/:id',
            name: 'purchases/pick/invoice/pick',
            component: Purchase,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/VFAs',
            name: 'purchases/VFAs',
            component: VFAs,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/purchases/:id',
            name: 'purchases/purchases/purchase',
            component: PurchasesDetail,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/purchases/:id/update',
            name: 'purchases/purchases/purchase/update',
            component: PurchasesDetailUpdate,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/VFAs/:id',
            name: 'purchases/VFAs/VFA',
            component: VFAsDetail,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/addLines/:id',
            name: 'purchases/addLines/purchase',
            component: AddPurchaseLines,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/outdated',
            name: 'purchases/outdated',
            component: PurchasesOutdated,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/openOrders',
            name: 'purchases/openOrders',
            component: OpenOrders,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/openOrders/:id',
            name: 'purchases/openOrders/detail',
            component: OpenOrderDetail,
            meta: {layout: "default"}
        },
        {
            path: '/orders/edifact/:id',
            name: 'orders/edifact/order',
            component: EdifactDetails,
            meta: {layout: "default"}
        },
        {
            path: '/orders/edifact',
            name: 'orders/edifact',
            component: Edifact,
            meta: {layout: "default"}
        },
        {
            path: '/orders/orders',
            name: 'orders/orders',
            component: Orders,
            meta: {layout: "default"}
        },
        {
            path: '/orders/bikes',
            name: 'orders/bikes',
            component: OrdersBikes,
            meta: {layout: "default"}
        },
        {
            path: '/orders/ordersAvailability',
            name: 'orders/ordersAvailability',
            component: OrdersAvailability,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pick',
            name: 'orders/pick',
            component: PickList,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pick-history',
            name: 'orders/pick-history',
            component: PickHistoryList,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pick-history/:id',
            name: 'orders/pick-history/detail',
            component: PickHistoryDetail,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pick/:id',
            name: 'orders/pick/pick',
            component: Pick,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pick/:id/view',
            name: 'orders/pick/pick/view',
            component: PickView,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pick/invoice/:id',
            name: 'orders/pick/invoice/pick',
            component: Invoice,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pick/purchase/:id',
            name: 'orders/pick/purchase/pick',
            component: Purchase,
            meta: {layout: "default"}
        },
        {
            path: '/orders/proformas',
            name: 'orders/proformas',
            component: Proformas,
            meta: {layout: "default"}
        },
        {
            path: '/orders/shipping',
            name: 'orders/shipping',
            component: Shipping,
            meta: {layout: "default"}
        },
        {
            path: '/orders/shippingv2',
            name: 'orders/shippingv2',
            component: ShippingV2,
            meta: {layout: "default"}
        },
        {
            path: '/orders/shippedPending',
            name: 'orders/shippedPending',
            component: ShippedPending,
            meta: {layout: "default"}
        },
        {
            path: '/orders/shippedHistory',
            name: 'orders/shippedHistory',
            component: ShippedHistory,
            meta: {layout: "default"}
        },
        {
            path: '/orders/histOrders',
            name: 'orders/histOrders',
            component: OrdersHistoric,
            meta: {layout: "default"}
        },
        {
            path: '/orders/shippingCosts',
            name: 'orders/shippingCosts',
            component: ShippingCost,
            meta: {layout: "default"}
        },
        {
            path: '/orders/pickups',
            name: 'orders/pickups',
            component: Pickups,
            meta: {layout: "default"}
        },
        {
            path: '/orders/deliveryDate',
            name: 'orders/deliveryDate',
            component: OrdersUpdateDate,
            meta: {layout: "default"}
        },
        {
            path: '/accounts/cosec',
            name: 'accounts/cosec',
            component: Cosec,
            meta: {layout: "default"}
        },
        {
            path: '/accounts/pending',
            name: 'accounts/pending',
            component: Pending,
            meta: {layout: "default"}
        },
        {
            path: '/accounts/credits',
            name: 'accounts/credits',
            component: Credits,
            meta: {layout: "default"}
        },
        {
            path: '/accounts/payments',
            name: 'accounts/payments',
            component: Payments,
            meta: {layout: "default"}
        },
        {
            path: '/accounts/gift-invoices',
            name: 'accounts/gift-invoices',
            component: GiftInvoices,
            meta: {layout: "default"}
        },
        {
            path: '/purchases/pdfs',
            name: 'purchases/pdfs',
            component: PdfsPurchases,
            meta: {layout: "default"}
        },
        {
            path: '/bikeplanet/pdfs-purchases',
            name: 'bikeplanet/pdfs-purchases',
            component: PdfsPurchasesBP,
            meta: {layout: "default"}
        },
        {
            path: '/offmax/credits',
            name: 'offmax/credits',
            component: CreditsOffmax,
            meta: {layout: "default"}
        },
        {
            path: '/debits/debits',
            name: 'debits/debits',
            component: Debits,
            meta: {layout: "default"}
        },
        {
            path: '/debits/addLines',
            name: 'debits/addLines',
            component: DebitsAddLines,
            meta: {layout: "default"}
        },
        {
            path: '/debits/detail/:id',
            name: 'debits/detail',
            component: DebitsDetail,
            meta: {layout: "default"}
        },
        {
            path: '/debits/historic',
            name: 'debits/historic',
            component: HistoricDebits,
            meta: {layout: "default"}
        },
        {
            path: '/debits/mandates',
            name: 'debits/mandates',
            component: DebitsMandates,
            meta: {layout: "default"}
        },
        {
            path: '/marketing/mailchimp',
            name: 'marketing/mailchimp',
            component: Mailchimp,
            meta: {layout: "default"}
        },
        {
            path: '/commercial/sales',
            name: 'commercial/sales',
            component: Sales,
            meta: {layout: "default"}
        },
        {
            path: '/commercial/reportsApp',
            name: 'commercial/reportsApp',
            component: ReportsApp,
            meta: {layout: "default"}
        },
        {
            path: '/commercial/reportsApp/:id',
            name: 'commercial/reportsAppDetail',
            component: ReportsAppDetail,
            meta: {layout: "default"}
        },
        {
            path: '/reportsCommercial/sales/zone',
            name: 'reportsCommercial/sales/zone',
            component: ReportsSalesZone,
            meta: {layout: "default"}
        },
        {
            path: '/reportsCommercial/sales/zoneClient',
            name: 'reportsCommercial/sales/zoneClient',
            component: ReportsSalesZoneClient,
            meta: {layout: "default"}
        },
        {
            path: '/reportsCommercial/sales/client/year',
            name: 'reportsCommercial/sales/client/year',
            component: ReportsSalesClientYear,
            meta: {layout: "default"}
        },
        {
            path: '/reportsCommercial/sales/client/brand',
            name: 'reportsCommercial/sales/client/brand',
            component: ReportsSalesClientBrand,
            meta: {layout: "default"}
        },
        {
            path: '/reportsCommercial/sales/brand',
            name: 'reportsCommercial/sales/brand',
            component: ReportsSalesBrand,
            meta: {layout: "default"}
        },
        {
            path: '/reportsAdmin/crm',
            name: 'reportsAdmin/crm',
            component: ReportsCrm,
            meta: {layout: "default"}
        },
        {
            path: '/reportsAdmin/sales/section',
            name: 'reportsAdmin/sales/section',
            component: ReportsAdminSalesSection,
            meta: {layout: "default"}
        },
        {
            path: '/primavera/intrastat',
            name: 'primavera/intrastat',
            component: Intrastat,
            meta: {layout: "default"}
        },
        {
            path: '/primavera/intrastatCompras',
            name: 'primavera/intrastatCompras',
            component: IntrastatPurchases,
            meta: {layout: "default"}
        },
        {
            path: '/primavera/profiles',
            name: 'primavera/profiles',
            component: Profiles,
            meta: {layout: "default"}
        },
        {
            path: '/administration/users',
            name: 'administration/users',
            component: AdministrationUsers,
            meta: {layout: "default"}
        },
        {
            path: '/administration/permissions',
            name: 'administration/permissions',
            component: AdministrationPermission,
            meta: {layout: "default"}
        },
        {
            path: '/administration/profiles',
            name: 'administration/profiles',
            component: AdministrationProfile,
            meta: {layout: "default"}
        },
        {
            path: '/administration/logins',
            name: 'administration/logins',
            component: AdministrationLogin,
            meta: {layout: "default"}
        },
        {
            path: '/administration/logs',
            name: 'administration/logs',
            component: Logs,
            meta: {layout: "default"}
        },
        {
            path: '/courses/places',
            name: 'courses/places',
            component: CoursesPlaces,
            meta: {layout: "default"}
        },
        {
            path: '/courses/levels',
            name: 'courses/levels',
            component: CoursesLevels,
            meta: {layout: "default"}
        },
        {
            path: '/courses/courses',
            name: 'courses/courses',
            component: Courses,
            meta: {layout: "default"}
        },
        {
            path: '/user/logout',
            // component:
        }
    ]
});

router.beforeEach((to, from, next) => {
    // i18n.locale = User.getters.getLanguage;
    if (to.path === '/user/logout') {
        User.dispatch('logout')
            .then(() => {
                next('/login')
            })
    }
    if (to.path !== '/login') {
        AppModel.dispatch('authenticated')
            .then(() => {
                if (to.path === '/') {
                    next('/dashboard')
                }
                next()
            })
            .catch(() => {
                next('/login')
            })
    }
    if (to.path === '/login') {
        AppModel.dispatch('authenticated')
            .then(() => {
                next('/dashboard')
            })
            .catch(() => {
                next()
            })
    }
});

export default router
