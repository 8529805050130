import { render, staticRenderFns } from "./BannersB2B.vue?vue&type=template&id=4186fc52&scoped=true&"
import script from "./BannersB2B.vue?vue&type=script&lang=js&"
export * from "./BannersB2B.vue?vue&type=script&lang=js&"
import style0 from "./BannersB2B.vue?vue&type=style&index=0&id=4186fc52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4186fc52",
  null
  
)

export default component.exports