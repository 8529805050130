import { render, staticRenderFns } from "./OpenOrderSupplierDetails.vue?vue&type=template&id=45424c33&scoped=true&"
import script from "./OpenOrderSupplierDetails.vue?vue&type=script&lang=js&"
export * from "./OpenOrderSupplierDetails.vue?vue&type=script&lang=js&"
import style0 from "./OpenOrderSupplierDetails.vue?vue&type=style&index=0&id=45424c33&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45424c33",
  null
  
)

export default component.exports