<template>
    <v-container fluid>
        <v-row v-if="!loading" class="content-header">
            <v-col>
                <page-title :title="clonedClient.businessName" :subtitle="'NIF:' + clonedClient.nif"></page-title>
            </v-col>
            <v-col style="display: flex;justify-content: flex-end;align-items: center;">
                <v-btn tile small color="success" class="mr-2" @click="sendClientToPrimavera()"
                       :disabled="disableSubmit">
                    CRIAR CLIENTE PRIMAVERA
                </v-btn>
                <v-btn tile small color="error" class="mr-2" @click="deleteRegistration()">
                    Apagar
                </v-btn>
            </v-col>
            <v-col cols="12" v-if="disableSubmit" class="errors-container">
                <p class="mb-0 mt-0 text-center" style="font-weight: bold;font-size: 18px;">
                    <v-icon class="mr-4 alert-icon" size="28" color="red">
                        mdi-alert
                    </v-icon>
                    Dados incompletos <span style="color: red;font-size: 16px">{{ this.errorMessage }}</span>
                    <v-icon class="ml-4 alert-icon" size="28" color="red">
                        mdi-alert
                    </v-icon>
                </p>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-col cols="6">
                <!-- CARD GERAL INFO -->
                <v-card>
                    <v-card-title class="pb-1 pt-1"
                                  style="background-color: #1976d2;color: white;text-transform: uppercase">Informação
                        Geral
                    </v-card-title>
                    <v-card-text class="mt-2">

                        <!--IDCLIENTE + TIPO PESSOA + TIPO TERCEIRO -->
                        <v-row>
                            <v-col>
                                <v-row class="mt-0">
                                    <v-col>
                                        <v-text-field
                                                v-model="clonedClient.iDClient"
                                                :error="clonedClient.iDClient === ''"
                                                dense
                                                outlined
                                                label="ID Cliente"
                                                hide-details
                                                style="max-width: 99.5%"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                                style="background-color: white"
                                                outlined
                                                dense
                                                hide-details
                                                density="compact"
                                                :items="tipoPessoa"
                                                item-value="value"
                                                item-text="text"
                                                required
                                                label="Tipo Pessoa"
                                                :error="clonedClient.tipoPessoa === ''"
                                                v-model="clonedClient.tipoPessoa">
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                                style="background-color: white; padding-bottom: 10px !important;"
                                                outlined
                                                dense
                                                hide-details
                                                density="compact"
                                                :items="tipoTerceiros"
                                                item-value="TipoTerceiro"
                                                item-text="Descricao"
                                                required
                                                label="Tipo Terceiro"
                                                :error="clonedClient.tipoTerceiro === ''"
                                                v-model="clonedClient.tipoTerceiro">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-divider class="mt-2 mb-4"></v-divider>

                        <!-- NAMES + EMAIL + PHONE -->
                        <v-row>
                            <v-col cols="12" class="pt-2 pb-0">
                                <v-text-field
                                        v-model="clonedClient.businessName"
                                        :error="clonedClient.businessName === ''"
                                        dense
                                        outlined
                                        label="Nome Comercial"
                                        hide-details
                                        class="pb-1 pt-1"
                                        style="max-width: 99.5%; padding-bottom: 10px !important;"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pt-0 pb-0">
                                <v-text-field
                                        v-model="clonedClient.financialName"
                                        :error="clonedClient.financialName === ''"
                                        dense
                                        outlined
                                        label="Nome Fiscal"
                                        hide-details
                                        class="pb-1 pt-1"
                                        style="max-width: 99.5%; padding-bottom: 10px !important;"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pt-0 pb-0">
                                <v-text-field
                                        v-model="clonedClient.email"
                                        dense
                                        outlined
                                        label="Email"
                                        hide-details
                                        class="pb-1 pt-1"
                                        style="max-width: 99.5%; padding-bottom: 10px !important;"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pt-0 pb-2">
                                <v-text-field
                                        v-model="clonedClient.phoneNumber"
                                        :error="clonedClient.phoneNumber === ''"
                                        dense
                                        outlined
                                        label="Nº Telemóvel"
                                        hide-details
                                        class="pb-1 pt-1"
                                        style="max-width: 99.5%; padding-bottom: 10px !important;"
                                ></v-text-field>
                            </v-col>

                        </v-row>

                        <v-divider class="mt-2 mb-4"></v-divider>

                        <!-- NIF + VIES -->
                        <v-row class="mt-0">
                            <v-col cols="6" class="pt-0 pb-2" style="max-width: 300px; display: flex;">
                                <v-text-field
                                        v-model="clonedClient.nif"
                                        :error="clonedClient.nif === ''"
                                        style="border-radius: 4px 0 0 4px;"
                                        dense
                                        outlined
                                        label="NIF"
                                        hide-details
                                        class="pt-1"
                                        v-on:keyup.enter="checkVies()"
                                >
                                </v-text-field>

                                <v-btn id="btn-refresh-vies" small outlined fab
                                       style="min-width:100px;min-height: 40px;margin-top: 4px;margin-left: -1px;border-radius: 0 4px 4px 0;"
                                       @click="checkVies()">
                                    <v-icon color="#0000FFFF">
                                        mdi-refresh
                                    </v-icon>
                                    <strong>VALIDAR</strong>
                                </v-btn>

                                <v-col v-if="vat.andorraMessage" style="max-height: 8px;" class="mt-0 pt-0">
                                    <i>{{ vat.andorraMessage }}</i>
                                </v-col>
                            </v-col>
                            <v-col class="mt-2 ml-0 pt-0 pb-2" style="max-width: 180px" cols="3">
                                <v-chip label style="font-size: 12px">
                                    <i class="fas fa-check" style="color: green" v-if="vat.valid"> VIES VALIDO</i>
                                    <i class="fas fa-times" style="color: red" v-if="!vat.valid"> VIES INVALIDO</i>
                                    <span style="font-weight: 900">&nbsp- {{ vat.countryCode }}</span>
                                    <!--                                    <v-img v-if="vat.countryCode == 'PT'" :src="require('../../assets/img/flag_pt.jpg')" max-width="40"></v-img>-->
                                    <!--                                    <v-img v-if="vat.countryCode == 'ES'" :src="require('../../assets/img/flag_es.jpg')" max-width="40"></v-img>-->

                                </v-chip>
                            </v-col>
                            <v-col class="mt-2 ml-0 pt-0 pb-2" cols="3">
                                <v-chip label style="font-size: 12px">
                                    <i class="fas fa-check" style="color: green" v-if="nifExistence <= 0"> NOVO NIF</i>
                                    <i class="fas fa-times" style="color: red" v-if="nifExistence > 0"> NIF
                                        DUPLICADO </i>
                                </v-chip>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <!-- CARD FINANCIAL INFO -->
                <v-card>
                    <v-card-title class="pb-1 pt-1"
                                  style="background-color: #ff9800;color: white;text-transform: uppercase">Informação
                        Financeira
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-row>
                            <!-- VENDEDOR -->
                            <v-col cols="6" class="pb-1">
                                <v-select
                                        style="background-color: white; padding-bottom: 10px !important;"
                                        outlined
                                        dense
                                        hide-details
                                        density="compact"
                                        :items="salesmen"
                                        item-value="Vendedor"
                                        item-text="Name"
                                        required
                                        label="Vendedor"
                                        class="pb-6"
                                        :error="clonedClient.salesman === ''"
                                        v-model="clonedClient.salesman">
                                </v-select>
                            </v-col>
                            <!-- MÉTODO DE PAGAMENTO -->
                            <v-col cols="6" class="pb-1">
                                <v-select
                                        style="background-color: white"
                                        outlined
                                        dense
                                        hide-details
                                        density="compact"
                                        :items="paymentMethods"
                                        item-value="text"
                                        item-text="text"
                                        required
                                        label="Método de Pagamento"
                                        :error="clonedClient.selectedPaymentMethod === ''"
                                        v-model="clonedClient.selectedPaymentMethod">
                                </v-select>
                            </v-col>
                            <!-- CONDIÇÕES DE PAGAMENTO -->
                            <v-col cols="6" class="pt-1 pb-1">
                                <v-select
                                        style="background-color: white; padding-bottom: 10px !important;"
                                        outlined
                                        dense
                                        hide-details
                                        density="compact"
                                        :items="paymentConditions"
                                        item-value="value"
                                        item-text="text"
                                        required
                                        label="Condição de Pagamento"
                                        :error="clonedClient.selectedPaymentCondition === ''"
                                        v-model="clonedClient.selectedPaymentCondition">
                                </v-select>
                            </v-col>
                            <!-- ESPAÇO FINANCEIRO -->
                            <v-col cols="6" class="pt-1 pb-1">
                                <v-select
                                        style="background-color: white"
                                        outlined
                                        dense
                                        hide-details
                                        density="compact"
                                        :items="financialSpace"
                                        item-value="value"
                                        item-text="text"
                                        required
                                        label="Espaço Financeiro"
                                        :error="clonedClient.financialSpace === ''"
                                        v-model="clonedClient.financialSpace">
                                </v-select>
                            </v-col>
                            <!-- REGIME IVA -->
                            <v-col cols="6" class="pt-1 pb-1">
                                <v-select
                                        style="background-color: white"
                                        outlined
                                        dense
                                        hide-details
                                        density="compact"
                                        :items="regimeIVA"
                                        item-value="value"
                                        item-text="text"
                                        required
                                        label="Regime de IVA"
                                        :error="clonedClient.regimeIVA === ''"
                                        v-model="clonedClient.regimeIVA">
                                </v-select>
                            </v-col>
                            <!-- LIMITE CRÉDITO -->
                            <v-col cols="6" class="pt-1 pb-1">
                                <v-text-field
                                        v-model="clonedClient.creditLimit"
                                        :error="clonedClient.creditLimit === ''"
                                        dense
                                        outlined
                                        label="Limite Crédito"
                                        hide-details
                                        style="max-width: 99.5%; padding-bottom: 10px !important;"
                                ></v-text-field>
                            </v-col>
                            <!-- CONTA CONTABILIDADE -->
<!--                            <v-col cols="6" class="pt-1 pb-1">-->
<!--                                <v-text-field-->
<!--                                        v-model="clonedClient.accountingAccount"-->
<!--                                        :error="clonedClient.accountingAccount === ''"-->
<!--                                        dense-->
<!--                                        outlined-->
<!--                                        label="Conta Contabilidade"-->
<!--                                        hide-details-->
<!--                                        style="max-width: 99.5%; padding-bottom: 10px !important;"-->
<!--                                ></v-text-field>-->
<!--                            </v-col>-->
                        </v-row>
                    </v-card-text>
                </v-card>

                <!-- OBSERVATIONS -->
                <v-card class="mt-2" v-if="clonedClient.observations != null && clonedClient.observations != ''">
                    <v-card-title class="pb-1 pt-1"
                                  style="background-color: orangered;color: white;text-transform: uppercase">
                        Observações
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-row>
                            <v-col>
                                {{ clonedClient.observations }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <!-- CARD ADDRESSES -->
                <v-card>
                    <v-card-title class="pb-1 pt-1"
                                  style="background-color: #009688;color: white;text-transform: uppercase">Moradas
                    </v-card-title>
                    <v-card-text>
                        <!-- MORADA FISCAL + MORADA ENTREGA -->
                        <v-row>
                            <!-- MORADA FISCAL -->
                            <v-col cols="6" style="border-right: 1px solid #009688;">
                                <h2 class="mb-2 mt-2" style="color: #009688">Morada Fiscal</h2>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                                v-model="clonedClient.address1"
                                                :error="clonedClient.address1 === ''"
                                                dense
                                                outlined
                                                label="Morada Fiscal Linha 1"
                                                hide-details
                                                class="pb-6 pt-1"
                                                style="max-width: 99.5% ; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                        <v-text-field
                                                v-model="clonedClient.address2"
                                                dense
                                                outlined
                                                label="Morada Fiscal Linha 2"
                                                hide-details
                                                class="pt-1"
                                                style="max-width: 99.5%; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                    <v-col cols="6">
                                        <v-text-field
                                                v-model="clonedClient.zipCode"
                                                :error="clonedClient.zipCode === ''"
                                                dense
                                                outlined
                                                label="Código Postal"
                                                hide-details
                                                class="pb-6"
                                                style="max-width: 99.5%; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                                v-model="clonedClient.location"
                                                :error="clonedClient.location === ''"
                                                dense
                                                outlined
                                                label="Localidade"
                                                hide-details
                                                class="pb-6"
                                                style="max-width: 99.5%; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-1">
                                        <v-select
                                                style="background-color: white; padding-bottom: 10px !important;"
                                                outlined
                                                dense
                                                hide-details
                                                density="compact"
                                                :items="countryOptions"
                                                item-value="value"
                                                item-text="name"
                                                required
                                                label="País"
                                                :error="clonedClient.country === ''"
                                                v-model="clonedClient.country">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-1">
                                        <v-select
                                                style="background-color: white; padding-bottom: 10px !important;"
                                                outlined
                                                dense
                                                hide-details
                                                density="compact"
                                                :items="zones"
                                                item-value="Zona"
                                                item-text="Descricao"
                                                required
                                                label="Zona"
                                                class="pb-6"
                                                :error="clonedClient.selectedZone === ''"
                                                v-model="clonedClient.selectedZone">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- MORADA ENTREGA -->
                            <v-col cols="6">
                                <h2 class="mb-2 mt-2" style="color: #009688">Morada de Entrega</h2>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                                v-model="clonedClient.deliveryAddress1"
                                                dense
                                                outlined
                                                label="Morada Entrega Linha 1"
                                                hide-details
                                                class="pb-6 pt-1"
                                                style="max-width: 99.5%; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                        <v-text-field
                                                v-model="clonedClient.deliveryAddress2"
                                                dense
                                                outlined
                                                label="Morada Entrega Linha 2"
                                                hide-details
                                                class="pt-1"
                                                style="max-width: 99.5%; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                    <v-col cols="6">
                                        <v-text-field
                                                v-model="clonedClient.deliveryZipCode"
                                                :error="clonedClient.deliveryAddress1 != '' && clonedClient.deliveryZipCode == ''"
                                                dense
                                                outlined
                                                label="Código Postal"
                                                hide-details
                                                style="max-width: 99.5%; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                                v-model="clonedClient.deliveryLocation"
                                                :error="clonedClient.deliveryAddress1 != '' && clonedClient.deliveryLocation == ''"
                                                dense
                                                outlined
                                                label="Localidade"
                                                hide-details
                                                style="max-width: 99.5%; padding-bottom: 10px !important;"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-1">
                                        <v-select
                                                style="background-color: white"
                                                outlined
                                                dense
                                                hide-details
                                                density="compact"
                                                :items="countryOptions"
                                                item-value="value"
                                                item-text="name"
                                                required
                                                label="País"
                                                :error="clonedClient.deliveryAddress1 != '' && clonedClient.deliveryCountry == ''"
                                                v-model="clonedClient.deliveryCountry">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-1">
                                        <v-select
                                                style="background-color: white"
                                                outlined
                                                dense
                                                hide-details
                                                density="compact"
                                                :items="zones"
                                                item-value="Zona"
                                                item-text="Descricao"
                                                required
                                                label="Zona"
                                                :error="clonedClient.deliveryAddress1 != '' && clonedClient.deliverySelectedZone == ''"
                                                v-model="clonedClient.deliverySelectedZone">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>


    </v-container>
</template>

<script>
export default {
    name: "RegistrationDetails",
    data: function () {
        return {
            loading: false,
            clientData: {},
            editedClientData: {},
            clonedClient: {
                businessName: '',
                financialName: '',
                email: '',
                phoneNumber: '',
                address1: '',
                zipCode: '',
                location: '',
                country: '',
                creditLimit: '4000',
                selectedZone: '',
                selectedPaymentMethod: '',
                selectedPaymentCondition: '',
                iDClient: '',
                tipoPessoa: '',
                salesman: '',
                financialSpace: '',
                regimeIVA: '',
                tipoTerceiro: '',
                deliverySelectedZone: '',
            },
            vat: [],
            nifExistence: '',
            zones: [],
            tipoTerceiros: [],
            tipoPessoa: [
                {value: true, text: 'Singular'},
                {value: false, text: 'Coletiva'}
            ],
            financialSpace: [
                {value: '0', text: 'Nacional'},
                {value: '1', text: 'Intracomunitário'},
                {value: '2', text: 'Externo'}
            ],
            regimeIVA: [
                {value: '0', text: 'Normal'},
                {value: '1', text: 'Inversão do Sujeito Passivo'},
                {value: '2', text: 'Isento'}
            ],
            salesmen: [],
            countryOptions: [
                {value: 'PT', name: 'Portugal'},
                {value: 'ES', name: 'Espanha'},
                {value: 'AD', name: 'Andorra'},
            ],
            paymentMethods: [],
            paymentConditions: [],
            errorMessage: ''
        }
    },
    created() {
        document.title = 'Detalhes de Registo | STAFF';
        this.getClientRegistrationDetails();
        this.getZones();
        this.getTipoTerceiros();
    },
    computed: {
        disableSubmit() {
            this.errorMessage = ''
            this.auxError = false;

            //Info Geral Cliente
            if (this.clonedClient.iDClient == '' || (this.clonedClient.tipoPessoa != true && this.clonedClient.tipoPessoa != false) || this.clonedClient.tipoTerceiro == '' || this.clonedClient.businessName == '' || this.clonedClient.financialName == '' || this.clonedClient.email == '' || this.clonedClient.phoneNumber == '') {
                this.errorMessage += " - Informação Geral"
                this.auxError = true
            }
            //Finacial info
            if (this.clonedClient.salesman == '' || this.clonedClient.selectedPaymentMethod == '' || this.clonedClient.financialSpace == '' || this.clonedClient.regimeIVA == '' || this.clonedClient.creditLimit == '' || this.clonedClient.selectedPaymentCondition == ''
                    // || this.clonedClient.accountingAccount == ''
            ) {
                this.errorMessage += " - Informação Financeira"
                this.auxError = true
            }
            //Address Fiscal
            if (this.clonedClient.address1 == '' || this.clonedClient.zipCode == '' || this.clonedClient.location == '' || this.clonedClient.country == '' || this.clonedClient.selectedZone == '') {
                this.errorMessage += " - Morada Fiscal"
                this.auxError = true
            }

            //NIF existente na nossa lista
            if (this.nifExistence) {
                this.errorMessage += " - NIF existente"
                this.auxError = true
            }

            //Se Address Entrega
            if (this.clonedClient.deliveryAddress1 != '' || this.clonedClient.deliveryAddress2 != '') {
                //Validar empty fields
                if (this.clonedClient.deliveryAddress1 == '' || this.clonedClient.deliveryCountry == '' || this.clonedClient.deliveryLocation == '' || this.clonedClient.deliverySelectedZone == '' || this.clonedClient.deliveryZipCode == '') {
                    this.errorMessage += " - Morada de Entrega"
                    this.auxError = true
                }
            }

            return this.auxError;
        }
    },
    methods: {
        sendClientToPrimavera() {
            let loader = this.$loading.show();
            this.loading = true;
            let request = {
                url: '/api/staff/clients/registrationPrimavera/' + this.$route.params.id,
                data: {
                    Client: this.clonedClient
                }
            }

            // console.log(this.clonedClient)
            this.updateRegistration();

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Cliente registado com sucesso!',
                                type: 'success'
                            })
                            this.getClientRegistrationDetails();
                        }

                        loader.hide()
                        this.loading = false;
                        this.$router.push({path:'/clients/clientsRegistration'})
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide()
                        this.loading = false;
                    })
            loader.hide()
            this.loading = false;
        },
        getClientRegistrationDetails() {
            let loader = this.$loading.show();
            this.loading = true;
            let request = {
                url: '/api/staff/clients/registration/' + this.$route.params.id,
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.clientData = responseData.clientData[0]


                            this.clientData.accountingAccount = '';
                            this.clientData.creditLimit = '4000';
                            this.clientData.selectedPaymentMethod = 'CNTRE';
                            this.clientData.iDClient = '';
                            this.clientData.tipoPessoa = '';
                            this.clientData.salesman = '';
                            this.clientData.financialSpace = '';
                            this.clientData.regimeIVA = '';
                            this.clientData.selectedPaymentCondition = '';
                            this.clientData.selectedZone = '';
                            this.clientData.tipoTerceiro = '';
                            this.clientData.deliverySelectedZone = '';
                            /*this.clonedClient.deliveryAddress1 = '';
                            this.clonedClient.deliveryAddress2 = '';
                            this.clonedClient.deliveryCountry = '';
                            this.clonedClient.deliveryLocation = '';
                            this.clonedClient.deliverySelectedZone = '';
                            this.clonedClient.deliveryZipCode = '';*/

                            this.clonedClient = JSON.parse(JSON.stringify(this.clientData));


                            this.paymentMethods = responseData.modesOfPayment.data;
                            this.paymentConditions = responseData.paymentConditions.data;
                            this.salesmen = responseData.salesmen.data;
                            this.vat = responseData.vat
                            this.nifExistence = responseData.nifExistence.Count;

                            for (var member in this.clonedClient) {
                                if (this.clonedClient[member] == null)
                                    this.clonedClient[member] = '';
                            }

                            loader.hide();
                            this.loading = false;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                        this.loading = false;
                    })

        },
        getZones() {
            let request = {
                url: '/api/staff/clients/zones/',
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.zones = responseData.zones;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        getTipoTerceiros() {
            let request = {
                url: '/api/staff/clients/tipoTerceiros/',
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            //   console.log('Hello')
                            this.tipoTerceiros = responseData.tipoTerceiros;
                            //   console.log(this.tipoTerceiros)
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        checkVies() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/clients/checkVies/',
                data: {
                    nif: {
                        country: this.clonedClient.country,
                        number: this.clonedClient.nif,
                    }
                }
            }
            // console.log(request)
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            // console.log(responseData)
                            this.vat = responseData.data;
                            this.nifExistence = responseData.data.nifExistente.Count;
                        }

                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
            // console.log(this.vat)
        },
        updateRegistration() {
            let loader = this.$loading.show();
            this.loading = true;
            let request = {
                url: '/api/staff/clients/registration/' + this.$route.params.id,
                data: {
                    client: this.clonedClient
                }
            }
            ApiWS.dispatch('makePut', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Cliente importado com sucesso!',
                                type: 'success'
                            })
                            //  console.log(this.clonedClient.iDClient)
                            // if (this.clonedClient.iDClient === '' || this.clonedClient.iDClient === undefined) {
                            //     console.log('getClientAgain')
                            //     this.getClientRegistrationDetails();
                            // } else {
                            //     console.log("Send primavera")
                            //     // this.sendClientToPrimavera();
                            // }
                        }
                        loader.hide();
                        this.loading = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                        this.loading = false;
                    })
        },
        deleteRegistration() {
            let loader = this.$loading.show();
            this.loading = true
            let request = {
                url: '/api/staff/clients/registration/' + this.$route.params.id,
            }

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            loader.hide()
                            this.$router.push({path: '/clients/clientsRegistration'})
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            loader.hide()
            this.$router.push('/clients/clientsRegistration')
        }
    }
}
</script>

<style scoped>

register-text-field input {
    font-size: 13px !important;
    --v-input-control-height: 30px !important;
    padding: 0px 0px 0px 10px !important;
}

#btn-refresh-vies {
    transition: all 0.5s ease-in-out;

    i {
        transition: all 0.5s ease-in-out;
    }

    &:hover {
        background-color: blue;
        border: 1px solid black;
        color: white !important;

        i {
            color: white !important;
        }
    }
}

.errors-container {
    transition: all 0.5s ease-in-out;
    background-color: black;
    color: white;

    .alert-icon {
        animation: 1s rotate_icon infinite;
    }
}

@keyframes rotate_icon {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>