import { render, staticRenderFns } from "./TableOrdersB2B.vue?vue&type=template&id=00724a96&scoped=true&"
import script from "./TableOrdersB2B.vue?vue&type=script&lang=js&"
export * from "./TableOrdersB2B.vue?vue&type=script&lang=js&"
import style0 from "./TableOrdersB2B.vue?vue&type=style&index=0&id=00724a96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00724a96",
  null
  
)

export default component.exports