<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('salesZoneClient')+' '+this.month+' / Secção 1'"
                        :subtitle="'A mostrar '+salesClient.length+' de '+totalSalesClient"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-row>
                    <v-col>
                        <v-btn small @click="download" color="success">Download</v-btn>
                    </v-col>
                    <v-col cols="2">
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        outlined
                        single-line
                        @change="changeSelect"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="width: 90px; margin-left: auto;"
                        class="staff_select mb-0"
                        hide-details
                ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="3" v-if="$writePermission('reports/filters') && salesman.length > 0">
                <v-select
                        v-model="filters.salesman_id"
                        label="Vendedor"
                        :items="salesman"
                        item-value="Vendedor"
                        item-text="Nome"
                        @change="changeSelect"
                        hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="brands.length > 0">
                <v-select
                        v-model="filters.brand"
                        label="Marca"
                        :items="brands"
                        item-text="TEXT"
                        item-value="VALUE"
                        @change="changeSelect"
                        hide-details
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Pais</th>
                            <th>Zona</th>
                            <th>Zona Comercial</th>
                            <th>Cliente</th>
                            <th>{{ $t('name') }}</th>
                            <th>{{ $t('taxName') }}</th>
                            <th class="text-right" @click="orderList(year(2))">{{ year(2) }} <i v-if="filters.selectedHeader === year(2)" class="fas"
                                                                    :class="[filters.selectedOrder === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th class="text-right" @click="orderList(year(1))">{{ year(1) }} <i v-if="filters.selectedHeader === year(1)" class="fas"
                                                                    :class="[filters.selectedOrder === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th class="text-right" @click="orderList(year(0))">{{ year(0) }} <i v-if="filters.selectedHeader === year(0)" class="fas"
                                                                    :class="[filters.selectedOrder === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        </tr>
                        <tr class="filters_row">
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.zone"
                                        :items="zones"
                                        item-value="zona"
                                        item-text="Descricao"
                                        @change="changeSelect"
                                        hide-details
                                        outlined
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.zonaComercial"
                                        :items="zonasComerciais"
                                        item-value="CDU_Id"
                                        item-text="CDU_Descricao"
                                        @change="changeSelect"
                                        hide-details
                                        outlined
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                    v-model="filters.client"
                                    @keypress.enter="changeSelect"
                                    hide-details
                                    single-line
                                    outlined
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                    v-model="filters.name"
                                    @keypress.enter="changeSelect"
                                    hide-details
                                    single-line
                                    outlined
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.fiscalName"
                                        @keypress.enter="changeSelect"
                                        hide-details
                                        single-line
                                        outlined
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="9">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="sale in salesClient">
                            <td>{{ sale.Pais }}</td>
                            <td>{{ sale.Descricao }}</td>
                            <td>{{ sale.CDU_Descricao }}</td>
                            <td>{{ sale.Cliente }}</td>
                            <td>{{ sale.Nome }}</td>
                            <td>{{ sale.NomeFiscal }}</td>
                            <td class="text-right">{{ $money(sale.YEAR_N_2) }}</td>
                            <td class="text-right">{{ $money(sale.YEAR_N_1) }}</td>
                            <td class="text-right">{{ $money(sale.YEAR_N) }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="initialize"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            filters: {
                salesman_id: '',
                client: '',
                zone: '',
                zonaComercial: '',
                name: '',
                fiscalName: '',
                brand: '',
                selectedHeader:  '',
                selectedOrder:  'asc'
            },
            salesman: [],
            zones: [],
            salesClient: [],
            totalSalesClient: 0,
            pagination: {
                page: 1,
                per_page: 250
            },
            loading: false,
            filtersPerPage: [
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            brands: [],
            zonasComerciais: []
        }),
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                this.loading = true
                this.salesClient = []

                let request = {
                    url: api.reportsSalesZoneClient(),
                    data: {
                        filters: this.filters,
                        pagination: this.pagination
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.salesman = responseData.salesman
                            this.zones = responseData.zones
                            this.salesClient = responseData.salesClient
                            this.totalSalesClient = responseData.totalSalesClient
                            this.brands = responseData.brands
                            this.zonasComerciais = responseData.zonasComerciais;
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
            },
            year(last) {
                return new Date().getFullYear() - last
            },
            changeSelect() {
                this.pagination.page = 1
                this.initialize()
            },
            toggleOrder(){
              if (this.filters.selectedOrder == 'asc'){
                  this.filters.selectedOrder = 'desc'
              }else {
                  this.filters.selectedOrder = 'asc'
              }
            },
            orderList(year) {
                if (year != this.filters.selectedHeader){
                    this.filters.selectedHeader = year;
                    this.filters.selectedOrder = 'asc';
                }
                else{
                    this.toggleOrder();
                }
                this.changeSelect();
            },
            download() {
                this.filters.pagination = this.pagination
                Reports.dispatch('getSalesByBrandDownload', this.filters)
                        .then(response => {
                            var file = new Blob([response.data], {
                                type: 'application/csv'
                            });
                            var fileURL = URL.createObjectURL(file);
                            var a = document.createElement('a');
                            a.href = fileURL;
                            a.target = '_blank';
                            a.download = 'Report_Zone_Client_' + this.filters.salesman_id + '_' + '.csv';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        })
                        .catch(response => {

                        })
            }
        },
        computed: {
            month() {
                return new Date().getMonth() + 1
            },
            getTotalPages() {
                return Math.ceil(this.totalSalesClient / this.pagination.per_page)
            }
        }
    }
</script>
